import React from "react";
import { Box, Typography } from "@material-ui/core";
import { PDF } from "./assets";
import CapOneCorpLogo from "../../../assets/b2b/ftux/capone-corp-icon.svg";
import { CAP_ONE_CORP_LOGO_ALT } from "../../../utils/constants";
import "./basePageStyles.scss";

export const PortalNotLive = () => {
  return (
    <Box className="not-live-page">
      <img
        className="not-live-page-logo"
        src={CapOneCorpLogo}
        alt={CAP_ONE_CORP_LOGO_ALT}
        aria-label={CAP_ONE_CORP_LOGO_ALT}
      />
      <hr />
      <Box className="not-live-page-content">
        <Typography variant="h1" className="not-live-page-title">
          Your site will be ready soon!
        </Typography>
        <Typography className="not-live-page-text">
          It looks like your business travel site is still being set up. We’ll
          send an invitation email when your company goes live so you can start
          booking travel.
        </Typography>
        <Typography className="not-live-page-text">
          <a href={PDF} target="_blank" rel="noopener noreferrer">
            Learn more
          </a>{" "}
          about Capital One Travel for Business.
        </Typography>
      </Box>
    </Box>
  );
};
