import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  CallState,
  Experiment,
  ExperimentState,
  LaunchedApplicationProperties,
} from "redmond";
import { fetchActiveExperiments } from "../api/v1/experiments/fetchExperiments";

// Active Experiments
export const PRICE_FREEZE = "c1-fintech-pricefreeze";
export const SEAT_SELECTION = "c1-marketplace-seatselection";
export const MAINTENANCE = "c1-marketplace-maintenancepage";
export const FLIGHTS_MAINTENANCE = "c1-marketplace-flights-maintenancepage";
export const HOTELS_MAINTENANCE = "c1-marketplace-hotels-maintenancepage";
export const CARS_MAINTENANCE = "c1-marketplace-cars-maintenancepage";
export const TRIPS_MAINTENANCE = "c1-marketplace-trips-maintenancepage";
export const TRAVEL_WALLET_OFFER_EXPERIMENT =
  "c1-marketplace-travel-wallet-offers";
export const TRAVEL_WALLET_CREDIT_EXPERIMENT =
  "c1-marketplace-travel-wallet-credits";
export const CASH_VALUEPROP_EXPERIMENT = `c1-marketplace-cash-valueprop-merch`;
export const MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT =
  "c1-marketplace-mobile-homescreen-redesign";
export const PREMIER_COLLECTION_EXPERIMENT =
  "c1-marketplace-luxury-hotels-collection";
export const CORPORATE_PORTAL_EXPERIMENT =
  "c1-marketplace-show-portal-switching-links";
export const HOTELS_HOMEPAGE_CROSS_SELL_EXPERIMENT =
  "c1-marketplace-homepage-hotel-cross-sell";
export const CREDIT_OFFER_STACKING_V1 =
  "c1-marketplace-credit-offer-stacking-v1";
export const TRAVEL_CREDIT_HISTORY_EXPERIMENT =
  "c1-marketplace-credit-transaction-history";
export const PREFERRED_HOTEL_EXPERIMENT =
  "c1-marketplace-cot-preferred-hotel-merch";
export const RECENTLY_VIEWED_MASTER_V1 = "c1-marketplace-recently-viewed_v1";
export const RECENTLY_VIEWED_DESKTOP_P0_EXPERIMENT =
  "c1-marketplace-recently-viewed-desktop-p0";
export const RECENTLY_VIEWED_MOBILE_P0_EXPERIMENT =
  "c1-marketplace-recently-viewed-mobile-p0";
export const RECENTLY_VIEWED_V2_FLIGHTS =
  "c1-marketplace-recently-viewed-v2-air";
export const RECENTLY_VIEWED_V2_CARS = "c1-marketplace-recently-viewed-v2-cars";
export const ANNUAL_TRAVEL_CREDITS = "c1-marketplace-annual-travel-credits";
export const LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT =
  "c1-marketplace-lifestyle-for-premium-cardholders";
export const CFAR_DISCOUNT = "c1-cfar-air-discount";
export const LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT =
  "c1-marketplace-lifestyle-for-non-premium-cardholders";
export const TRAVEL_SALE = "c1-marketplace-travel-sale";
export const VR_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT =
  "c1-marketplace-vr-premium-cards";
export const VR_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT =
  "c1-marketplace-vr-non-premium-cards";
export const POST_BOOKING_OFFER_EXPERIMENT =
  "c1-fintech-disruption-air-postbookingoffer";
export const POST_BOOKING_OFFER_EXPERIMENT_REWARDS =
  "c1-fintech-disruption-air-postbookingoffer-rewards";
export const DISRUPTION_AIR_SUMMER_COPY =
  "c1-fintech-disruption-air-summer-2024-copy";
export const CUSTOMER_PROFILE_EXPERIMENT = "c1-marketplace-customer-profile";
export const COACHELLA_OFFER_EXPERIMENT = "c1-marketplace-coachella-offer";
export const RESTRICT_FUNNELS = "c1-agency-restrict-funnels";
export const GLOBAL_MOBILE_NAV_EXPERIMENT = "c1-marketplace-global-mobile-nav";
export const HOTEL_CROSS_SELL_V3 = "c1-marketplace-hotel-cross-sell-v3";
export const HOTELS_TAXES_AND_FEES_EXPERIMENT =
  "c1-marketplace-HotelsCaliforniaBill537";
export const PACKAGES_EXPERIMENT = "c1-marketplace-packages";
export const LUXURY_VACATION_RENTAL_FUNNEL_LAUNCH = 
  "c1-luxury-vacation-rental-funnel-launch";

// Default variants
export const CONTROL = "control";
export const AVAILABLE = "available";
export const VARIANT_1 = "variant-1";
export const VARIANT_2 = "variant-2";

// variants for c1-cfar-air-discount
export const CFAR_DISCOUNT_CONTROL = "control";
export const CFAR_DISCOUNT_10_PERCENT = "10percentdiscount";
export const CFAR_DISCOUNT_25_PERCENT = "25percentdiscount";
export const CFAR_DISCOUNT_75_PERCENT = "75percentdiscount";
export const CFAR_DISCOUNT_100_PERCENT = "100percentdiscount";
export const CFAR_DISCOUNT_VARIANTS = [
  CFAR_DISCOUNT_CONTROL,
  CFAR_DISCOUNT_10_PERCENT,
  CFAR_DISCOUNT_25_PERCENT,
  CFAR_DISCOUNT_75_PERCENT,
  CFAR_DISCOUNT_100_PERCENT,
] as const;
export type CfarDiscountVariantType =
  | typeof CFAR_DISCOUNT_CONTROL
  | typeof CFAR_DISCOUNT_10_PERCENT
  | typeof CFAR_DISCOUNT_25_PERCENT
  | typeof CFAR_DISCOUNT_75_PERCENT
  | typeof CFAR_DISCOUNT_100_PERCENT;
// Variants for c1-marketplace-cash-valueprop-merch
export const CASH_VALUEPROP_A = "cash-valueprop-a";
export const CASH_VALUEPROP_B = "cash-valueprop-b";
export const CASH_VALUEPROP_C = "cash-valueprop-c";
export const CASH_VALUEPROP_VARIANTS = [
  CONTROL,
  CASH_VALUEPROP_A,
  CASH_VALUEPROP_B,
  CASH_VALUEPROP_C,
] as const;
export type CashValuePropVariantType =
  | typeof CONTROL
  | typeof CASH_VALUEPROP_A
  | typeof CASH_VALUEPROP_B
  | typeof CASH_VALUEPROP_C;

// Variants for c1-marketplace-mobile-homescreen-redesign
export const MOBILE_HOMESCREEN_REDESIGN_V1 = "variant-1";
export const MOBILE_HOMESCREEN_REDESIGN_V2 = "variant-2";
export const MOBILE_HOMESCREEN_REDESIGN_V3 = "variant-3";
export const MOBILE_HOMESCREEN_REDESIGN_VARIANTS = [
  CONTROL,
  MOBILE_HOMESCREEN_REDESIGN_V1,
  MOBILE_HOMESCREEN_REDESIGN_V2,
  MOBILE_HOMESCREEN_REDESIGN_V3,
] as const;

export type MobileHomescreenRedesignPropVariantType =
  | typeof CONTROL
  | typeof MOBILE_HOMESCREEN_REDESIGN_V1
  | typeof MOBILE_HOMESCREEN_REDESIGN_V2
  | typeof MOBILE_HOMESCREEN_REDESIGN_V3;

export const RECENTLY_VIEWED_DESKTOP_P0_V1 = "variant-1";
export const RECENTLY_VIEWED_DESKTOP_P0_V2 = "variant-2";
export const RECENTLY_VIEWED_DESKTOP_P0_V3 = "variant-3";
export const RECENTLY_VIEWED_DESKTOP_P0_V4 = "variant-4";
export const RECENTLY_VIEWED_DESKTOP_P0_VARIANTS = [
  CONTROL,
  RECENTLY_VIEWED_DESKTOP_P0_V1,
  RECENTLY_VIEWED_DESKTOP_P0_V2,
  RECENTLY_VIEWED_DESKTOP_P0_V3,
  RECENTLY_VIEWED_DESKTOP_P0_V4,
] as const;

export type RecentlyViewedDesktopP0PropVariantType =
  | typeof CONTROL
  | typeof RECENTLY_VIEWED_DESKTOP_P0_V1
  | typeof RECENTLY_VIEWED_DESKTOP_P0_V2
  | typeof RECENTLY_VIEWED_DESKTOP_P0_V3
  | typeof RECENTLY_VIEWED_DESKTOP_P0_V4;

// Variants for c1-marketplace-cot-preferred-hotel-merch
export const PREFERRED_HOTELS_V1 = "v1-special-rates";
export const PREFERRED_HOTELS_VARIANT = [CONTROL, PREFERRED_HOTELS_V1] as const;
export type PreferredHotelsPropVariantType =
  | typeof CONTROL
  | typeof PREFERRED_HOTELS_V1;
export const RECENTLY_VIEWED_MOBILE_P0_V1 = "variant-1";
export const RECENTLY_VIEWED_MOBILE_P0_V2 = "variant-2";
export const RECENTLY_VIEWED_MOBILE_P0_V3 = "variant-3";
export const RECENTLY_VIEWED_MOBILE_P0_VARIANTS = [
  CONTROL,
  RECENTLY_VIEWED_MOBILE_P0_V1,
  RECENTLY_VIEWED_MOBILE_P0_V2,
  RECENTLY_VIEWED_MOBILE_P0_V3,
] as const;

export type RecentlyViewedMobileP0PropVariantType =
  | typeof CONTROL
  | typeof RECENTLY_VIEWED_MOBILE_P0_V1
  | typeof RECENTLY_VIEWED_MOBILE_P0_V2
  | typeof RECENTLY_VIEWED_MOBILE_P0_V3;

export const LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS = [
  CONTROL,
  VARIANT_1,
  VARIANT_2,
] as const;

export type LCForPremiumCardholdersPropVariantType =
  | typeof CONTROL
  | typeof VARIANT_1
  | typeof VARIANT_2;

export const LC_FOR_NON_PREMIUM_CARDHOLDERS_V1 = "variant-1";
export const LC_FOR_NON_PREMIUM_CARDHOLDERS_V2 = "variant-2";
export const LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS = [
  CONTROL,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_V1,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_V2,
] as const;

export type LCForNonPremiumCardholdersPropVariantType =
  | typeof CONTROL
  | typeof LC_FOR_NON_PREMIUM_CARDHOLDERS_V1
  | typeof LC_FOR_NON_PREMIUM_CARDHOLDERS_V2;

// Variants for c1-marketplace-show-portal-switching-links
export const ONE_PORTAL_VARIANT = "oneportal";

// Variants for c1-marketplace-travel-sale
export const TRAVEL_SALE_LEAD_UP = "lead-up";
export const TRAVEL_SALE_ACTIVE = "active";
export const TRAVEL_SALE_VARIANTS = [
  CONTROL,
  TRAVEL_SALE_LEAD_UP,
  TRAVEL_SALE_ACTIVE,
] as const;
export type TravelSalePropVariantType =
  | typeof CONTROL
  | typeof TRAVEL_SALE_LEAD_UP
  | typeof TRAVEL_SALE_ACTIVE;

// Variants for c1-marketplace-customer-profile
export const DEFAULT_OFF = "default-off";
export const DEFAULT_ON = "default-on";
export const CUSTOMER_PROFILE_VARIANTS = [
  CONTROL,
  DEFAULT_OFF,
  DEFAULT_ON,
] as const;
export type CustomerProfileVariantType =
  | typeof CONTROL
  | typeof DEFAULT_OFF
  | typeof DEFAULT_ON;

// Variants for c1-marketplace-hotel-cross-sell-v3
export const DOLLAR_OFFER = "dollar-offer";
export const PERCENTAGE_OFFER = "percentage-offer";
export const HOTEL_CROSS_SELL_V3_VARIANTS = [
  CONTROL,
  DOLLAR_OFFER,
  PERCENTAGE_OFFER,
] as const;
export type HotelCrossSellV3VariantTYpe =
  | typeof CONTROL
  | typeof DOLLAR_OFFER
  | typeof PERCENTAGE_OFFER;

//Variants for c1-agency-restrict-funnels
export const CARS_ONLY = "cars-only";
export const FLIGHTS_ONLY = "flights-only";
export const HOTELS_ONLY = "hotels-only";
export const PREMIUM_STAYS_ONLY = "premium-stays-only";
export const RESTRICT_FUNNELS_VARIANTS = [
  CONTROL,
  CARS_ONLY,
  FLIGHTS_ONLY,
  HOTELS_ONLY,
  PREMIUM_STAYS_ONLY,
] as const;
export type RestrictFunnelsVariantType =
  | typeof CONTROL
  | typeof CARS_ONLY
  | typeof FLIGHTS_ONLY
  | typeof HOTELS_ONLY
  | typeof PREMIUM_STAYS_ONLY;

// Variants for c1-marketplace-HotelsCaliforniaBill537
export const LEAST_DISRUPTION = "least-disruption";
export const BEST_COMPLIANCE = "best-compliance";
export const HOTELS_TAXES_AND_FEES_VARIANTS = [
  CONTROL,
  LEAST_DISRUPTION,
  BEST_COMPLIANCE,
] as const;
export type HotelsTaxesAndFeesVariantType =
  | typeof CONTROL
  | typeof LEAST_DISRUPTION
  | typeof BEST_COMPLIANCE;

// Variants for c1-marketplace-packages
export const SELF_FUNDED = "self-funded";
export const NOT_SELF_FUNDED = "not-self-funded";
export const PACKAGES_EXPERIMENT_VARIANTS = [
  CONTROL,
  SELF_FUNDED,
  NOT_SELF_FUNDED,
] as const;
export type PackagesExperimentVariantType =
  | typeof CONTROL
  | typeof SELF_FUNDED
  | typeof NOT_SELF_FUNDED;

const defaultInitializer = (): ExperimentStateWithCallState => {
  return {
    experiments: [],
    trackingProperties: undefined,
    callState: CallState.NotCalled,
  };
};

export const ExperimentsContext = createContext<
  ExperimentStateWithCallState | undefined
>(undefined);

// readonly (together with const restriction below) ensures typeof supportedVariants[number]
// can be recognized by transpiler as an array of constants
// [string, ...string[]] ensures array is not empty.
export function getExperimentVariantCustomVariants<
  T extends readonly [string, ...string[]]
>(
  experiments: Array<Experiment>,
  experimentId: string,
  // List of supported variants. If the variant retreived is not recognized by the code, the code will fall back to the first variant.
  // Note the input list needs to be declared as const e.g. const DEFAULT_VARIANTS = [CONTROL, AVAILABLE] as const;
  supportedVariants: T
): (typeof supportedVariants)[number] {
  const experiment = experiments?.find((exp) => {
    if (exp.id === experimentId) {
      return exp;
    }
    return;
  });

  if (experiment) {
    if (supportedVariants.includes(experiment.variant)) {
      return experiment.variant;
    } else {
      return supportedVariants[0];
    }
  } else {
    return supportedVariants[0];
  }
}

export function getExperimentVariant(
  experiments: Array<Experiment>,
  experimentId: String
): string {
  const experiment = experiments?.find((exp) => {
    if (exp.id === experimentId) {
      return exp;
    }
    return;
  });

  if (experiment) {
    return experiment.variant;
  } else {
    return CONTROL;
  }
}

export function useExperiments(): ExperimentStateWithCallState {
  const ctx = useContext(ExperimentsContext);
  if (!ctx) throw new Error(`must be used within a ExperimentsProvider`);
  return ctx;
}

export function addTrackingProperties(
  trackingProperties: Map<string, string> | undefined,
  properties: LaunchedApplicationProperties
): LaunchedApplicationProperties {
  if (trackingProperties) {
    properties["experiments"] = Object.keys(trackingProperties).map(
      (ex) => `${ex}_${trackingProperties![ex]}`
    );
  }
  return properties;
}

type ExperimentsProviderProps = {
  initState?: ExperimentState;
  isLoggedIn?: boolean;
};
interface ExperimentStateWithCallState extends ExperimentState {
  callState: CallState;
}

const ExperimentsProvider: FC<
  ExperimentsProviderProps &
    PropsWithChildren<{ initState?: ExperimentStateWithCallState }>
> = ({ initState = defaultInitializer(), isLoggedIn, children }) => {
  const [state, setState] = useState(initState);

  useEffect(() => {
    if (!initState?.experiments.length && isLoggedIn) {
      const fetchExperiments = async () => {
        setState({ ...state, callState: CallState.InProcess });
        await fetchActiveExperiments().then((result) => {
          setState({
            ...(result as ExperimentState),
            callState: CallState.Success,
          });
        });
      };
      fetchExperiments();
    }
  }, [isLoggedIn]);

  return (
    <ExperimentsContext.Provider value={state}>
      {children}
    </ExperimentsContext.Provider>
  );
};

export default ExperimentsProvider;
