import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  HeaderHomePageMiddleSection,
  HeaderMenuButtonType,
  Icon,
  IconName,
  VacationRentalNewTag,
} from "halifax";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { RewardsAccount } from "redmond";
import {
  AVAILABLE,
  CARS_ONLY,
  CONTROL,
  FLIGHTS_ONLY,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  HOTELS_ONLY,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS,
  LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS,
  LUXURY_VACATION_RENTAL_FUNNEL_LAUNCH,
  MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
  MOBILE_HOMESCREEN_REDESIGN_V2,
  MOBILE_HOMESCREEN_REDESIGN_VARIANTS,
  NOT_SELF_FUNDED,
  PACKAGES_EXPERIMENT,
  PACKAGES_EXPERIMENT_VARIANTS,
  PREMIER_COLLECTION_EXPERIMENT,
  PREMIUM_STAYS_ONLY,
  RESTRICT_FUNNELS,
  RESTRICT_FUNNELS_VARIANTS,
  RestrictFunnelsVariantType,
  SELF_FUNDED,
  useExperiments,
  VR_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
} from "../../../context/experiments";
import {
  PATH_CARS,
  PATH_FLIGHTS,
  PATH_HOTELS,
  PATH_PACKAGES,
  PATH_PREMIER_COLLECTION,
} from "../../../utils/urlPaths";
import { isCaponeTenant } from "@capone/common";
import { config } from "../../../api/config";

interface FunnelEntryTabsProps extends RouteComponentProps {
  rewardsAccounts?: RewardsAccount[];
  isMobile?: boolean;
}

export const FunnelEntryTabs = (props: FunnelEntryTabsProps) => {
  const { history, isMobile } = props;

  const expState = useExperiments();

  const vacationFunnelLaunchEnabled =
    getExperimentVariant(
      expState.experiments,
      LUXURY_VACATION_RENTAL_FUNNEL_LAUNCH
    ) === AVAILABLE;

  const mobileHomeScreenVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
    MOBILE_HOMESCREEN_REDESIGN_VARIANTS
  );

  const isMobileHomeScreenVariant2 =
    isMobile && mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2;

  const isPremierCollectionEnabled =
    getExperimentVariant(
      expState.experiments,
      PREMIER_COLLECTION_EXPERIMENT
    ) === AVAILABLE &&
    (isMobile // These experiments should be at 100% when premier collection launches so we updated FE using redesign but adding this check so theres no weird styling on staging accts where this isn't enabled
      ? mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2
      : true);

  const LCForPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForPremiumCardHoldersEnabled =
    !isMobile && LCForPremiumCardholderVariant !== CONTROL;

  const LCForNonPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForNonPremiumCardHoldersEnabled =
    LCForNonPremiumCardholderVariant !== CONTROL;

  const isVRForNonPremiumCardHoldersEnabled =
    getExperimentVariant(
      expState.experiments,
      VR_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT
    ) === AVAILABLE;

  const restrictFunnelVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    RESTRICT_FUNNELS,
    RESTRICT_FUNNELS_VARIANTS
  );

  const packagesExperimentVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    PACKAGES_EXPERIMENT,
    PACKAGES_EXPERIMENT_VARIANTS
  );

  const isPackagesExperiment = [SELF_FUNDED, NOT_SELF_FUNDED].includes(
    packagesExperimentVariant
  );

  const hotelsButton = {
    type: HeaderMenuButtonType.Hotels,
    selected: history.location.pathname === PATH_HOTELS,
    content: (
      <Box className="funnel-button-content">
        <Icon name={IconName.HotelFunnelIcon} />
        <Typography>Hotels</Typography>
      </Box>
    ),
    onClick: () => history.push(PATH_HOTELS),
  };

  const flightsButton = {
    type: HeaderMenuButtonType.Flights,
    selected: history.location.pathname === PATH_FLIGHTS,
    content: (
      <Box className="funnel-button-content">
        <Icon name={IconName.FlightFunnelIcon} />

        <Typography>Flights</Typography>
      </Box>
    ),
    onClick: () => history.push(PATH_FLIGHTS),
  };

  const carsButton = {
    type: HeaderMenuButtonType.Cars,
    selected: history.location.pathname === PATH_CARS,
    content: (
      <Box className="funnel-button-content">
        <Icon name={IconName.CarFunnelIcon} />
        <Typography>
          {isMobileHomeScreenVariant2 ? "Cars" : "Rental Cars"}
        </Typography>
      </Box>
    ),
    onClick: () => history.push(PATH_CARS),
  };

  const premiumStaysButton =
    isLCForNonPremiumCardHoldersEnabled && !isVRForNonPremiumCardHoldersEnabled
      ? [
          {
            type: HeaderMenuButtonType.PremierCollection,
            selected: history.location.pathname.includes(
              PATH_PREMIER_COLLECTION
            ),
            content: (
              <Box className="funnel-button-content lifestyle-collection">
                <Icon name={IconName.LifestyleCollectionIcon} />
              </Box>
            ),
            onClick: () => history.push(PATH_PREMIER_COLLECTION),
          },
        ]
      : isLCForPremiumCardHoldersEnabled || isVRForNonPremiumCardHoldersEnabled
      ? [
          {
            type: HeaderMenuButtonType.PremierCollection,
            selected: history.location.pathname.includes(
              PATH_PREMIER_COLLECTION
            ),
            content: (
              <Box className="funnel-button-content premium-stays">
                <Icon name={IconName.StarIconWithLines} />
                <Typography>Premium Stays</Typography>
                <VacationRentalNewTag enabled={vacationFunnelLaunchEnabled} />
              </Box>
            ),
            onClick: () => history.push(PATH_PREMIER_COLLECTION),
          },
        ]
      : isPremierCollectionEnabled
      ? [
          {
            type: HeaderMenuButtonType.PremierCollection,
            selected: history.location.pathname === PATH_PREMIER_COLLECTION,
            content: (
              <Box className="funnel-button-content premier-collection">
                <Icon name={IconName.PremierCollectionVerticalIcon} />
              </Box>
            ),
            onClick: () => history.push(PATH_PREMIER_COLLECTION),
          },
        ]
      : [];

  const packagesButton = isPackagesExperiment
    ? [
        {
          type: HeaderMenuButtonType.Packages,
          selected: history.location.pathname === PATH_PACKAGES,
          content: (
            <Box className="funnel-button-content">
              <div className="packages-icon-wrapper">
                <Icon name={IconName.HotelFunnelIcon} />
                <Typography className="icon-plus-separator">+</Typography>
                <Icon name={IconName.FlightFunnelIcon} />
              </div>
              <Typography>Packages</Typography>
            </Box>
          ),
          onClick: () => history.push(PATH_PACKAGES),
        },
      ]
    : [];

  const buttons = [
    isMobileHomeScreenVariant2 ? hotelsButton : flightsButton,
    isMobileHomeScreenVariant2 ? flightsButton : hotelsButton,
    ...packagesButton,
    carsButton,
    ...(isCaponeTenant(config.TENANT) ? premiumStaysButton : []),
  ];

  if (isMobile && mobileHomeScreenVariant !== MOBILE_HOMESCREEN_REDESIGN_V2) {
    return null;
  }

  const getButtons = (variant: RestrictFunnelsVariantType) => {
    switch (variant) {
      case CARS_ONLY:
        return [carsButton];
      case FLIGHTS_ONLY:
        return [flightsButton];
      case HOTELS_ONLY:
        return [hotelsButton];
      case PREMIUM_STAYS_ONLY:
        return premiumStaysButton;
      case CONTROL:
      default:
        return buttons;
    }
  };

  return (
    <HeaderHomePageMiddleSection
      className={clsx("b2b", {
        mobile: isMobile,
        "redesign-v2":
          mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2,
      })}
      buttons={getButtons(restrictFunnelVariant)}
      {...props}
    />
  );
};
