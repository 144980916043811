import React, { useLayoutEffect } from "react";
import { useTheme } from "@material-ui/core";
import { AuthModuleProps } from "redmond";
import { useDeviceTypes } from "halifax";
import { RouteComponentProps } from "react-router-dom";
import { isCorpTenant } from "@capone/common";

import {
  PAWTUCKET_AUTH_MODULE_ID,
  PAWTUCKET_AGENT_AUTH_MODULE_ID,
} from "../../utils/moduleIds";
import useMicrofrontend from "../../hooks/useMicrofrontend";
import config from "../../utils/config";
import { colors as caponeColors } from "../../utils/capone/colors";
import { colors as corpColors } from "../../utils/capone-corporate/colors";
import CapOneLogo from "../../assets/b2b/ftux/capone-icon.svg";
import CorpLogo from "../../assets/b2b/ftux/corp-capone-icon.svg";
import DesktopScreenshot from "../../assets/b2b/ftux/price-prediction-screenshot-desktop.svg";
import DesktopNoRewardsScreenshot from "../../assets/b2b/ftux/price-prediction-screenshot-desktop-no-rewards.svg";
import MobileScreenshot from "../../assets/b2b/ftux/price-prediction-screenshot-mobile.svg";
import MobileNoRewardsScreenshot from "../../assets/b2b/ftux/price-prediction-screenshot-mobile-no-rewards.svg";
import "./styles.scss";
import clsx from "clsx";
import { CAP_ONE_CORP_LOGO_ALT, CAP_ONE_LOGO_ALT } from "../../utils/constants";
import * as constants from "./constants";

interface IAuthModuleProps extends RouteComponentProps {
  language: string;
}

const logo = {
  capone: <img src={CapOneLogo} alt={CAP_ONE_LOGO_ALT} />,
  "capone-corporate": <img src={CorpLogo} alt={CAP_ONE_CORP_LOGO_ALT} />,
};

const AuthModule = (props: IAuthModuleProps) => {
  const { language, ...routerProps } = props;
  const { history } = routerProps;

  const id = config.ptIsAgentPortal
    ? PAWTUCKET_AGENT_AUTH_MODULE_ID
    : PAWTUCKET_AUTH_MODULE_ID;

  const theme = useTheme();
  const { matchesMobile } = useDeviceTypes();
  const { isLoaded, [id]: ptAuthModule } = useMicrofrontend(
    id,
    (config.ptAuthModuleUrl += "")
  );

  useLayoutEffect(() => {
    if (!ptAuthModule) return;
    const { render, unMount } = ptAuthModule;
    const authModuleProps: AuthModuleProps = {
      theme,
      isAgentPortal: config.ptIsAgentPortal,
      language: language,
      colors: isCorpTenant(config.TENANT) ? corpColors : caponeColors,
      baseHistory: history,
      clientAssets: {
        logo: logo[config.TENANT],
        priceDropScreenshotDesktop: <img src={DesktopScreenshot} alt="" />,
        priceDropScreenshotMobile: <img src={MobileScreenshot} alt="" />,
        priceDropScreenshotNoRewardsDesktop: (
          <img src={DesktopNoRewardsScreenshot} alt="" />
        ),
        priceDropScreenshotNoRewardsMobile: (
          <img src={MobileNoRewardsScreenshot} alt="" />
        ),
        ineligibleAccountRedirectURL: constants.INELIGIBLE_ACCOUNT_REDIRECT_URL,
        badStatusRedirectURL: constants.BAD_STATUS_REDIRECT_URL,
      },
    };
    render(id, authModuleProps);
    return () => {
      unMount(id);
    };
  }, [isLoaded]); // eslint-disable-line

  if (!isLoaded) return null;
  if (!ptAuthModule) return <h2>ERROR! Cannot find Module</h2>;
  return (
    <div
      className={clsx("auth-module-container", {
        mobile: matchesMobile,
      })}
      id={id}
    />
  );
};

export default AuthModule;
