export const PAWTUCKET_FLIGHTS_MODULE_ID = "ptFlightsModule";
export const PAWTUCKET_HOTELS_MODULE_ID = "ptHotelsModule";
export const PAWTUCKET_TRIPS_MODULE_ID = "ptTripsModule";
export const PAWTUCKET_TERMS_MODULE_ID = "ptTermsModule";
export const PAWTUCKET_CARS_MODULE_ID = "ptCarsModule";
export const PAWTUCKET_AUTH_MODULE_ID = "ptAuthModule";
export const PAWTUCKET_AGENT_AUTH_MODULE_ID = "ptAgentAuthModule";
export const PAWTUCKET_PREMIER_COLLECTION_MODULE_ID =
  "ptPremierCollectionModule";
export const PAWTUCKET_EXCHANGE_MODULE_ID = "ptExchangeModule";
export const PAWTUCKET_TRAVEL_WALLET_MODULE_ID = "ptTravelWalletModule";
export const PAWTUCKET_CUSTOMER_PROFILE_MODULE_ID = "ptCustomerProfileModule";
export const PAWTUCKET_PACKAGES_MODULE_ID = "ptPackagesModule";
