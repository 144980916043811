import axios from "axios";
import { config } from "../config";
import { fetchUserBusinessAuthorizedPath } from "../paths";
import { CorpSessionInfo } from "redmond";

const fetchBusinessEligibility = (): Promise<CorpSessionInfo> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.get(fetchUserBusinessAuthorizedPath, {
        baseURL: config.corpPortalBaseUrl,
        withCredentials: true,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default fetchBusinessEligibility;
