import { PATH_CORP_FAQ } from "@capone/common";

export const SUPPORT_LINK = "Support";

export const SUPPORT_LINK_URL = {
  capone: "https://www.capitalone.com/help-center/capital-one-travel/",
  "capone-corporate": PATH_CORP_FAQ,
};

export const CONTACT_SUPPORT_HEADER = "Get Help With Capital One Travel";
export const CONTACT_SUPPORT_TEXT_1 =
  "For information regarding Seats, Baggage, Changes, Cancellations and Refunds, please visit";
export const FREQUENTLY_ASKED_QUESTIONS = "Frequently Asked Questions";
export const CONTACT_SUPPORT_TEXT_2 =
  "For other questions regarding your trip, please contact our support team and we’d be happy to help.";
export const CONTACT_SUPPORT_NUMBER = "844-422-6922";

export const QUESTIONS_ABOUT_CAP1_TRAVEL =
  "Questions about Capital One Travel?";

export const GET_HELP = "Get Help";
export const SIGN_IN = "Sign in";

export const CUTOMER_PROFILE_TITLE = "My travel profile";
export const NEW = "New";
export const CUSTOMER_PROFILE_SUBTITLE =
  "Manage travel preferences, frequent flyer programs, payment methods, and more.";
