import React, {useEffect, useRef, useState} from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  SwipeableDrawer,
  Theme,
  Typography,
} from "@material-ui/core";
import {CorpBusinessAccount} from "redmond/apis/tysons/businesses";
import {makeStyles} from "@material-ui/core/styles";
import {changeBusinessInUse} from "../../../../api/v1/multi-account/changeBusinessInUse";
import {
  CORP_SWITCH_ACCOUNT,
  CORP_SWITCH_ACCOUNT_CONTINUE,
  CorpSessionInfo,
  CustomerAccountRole,
  MODAL_ALERT,
  MODAL_ALERT_CHOICE
} from "redmond";
import {CorpAccountItemModal, useDeviceTypes} from "halifax";
import {trackEvent} from "../../../../api/v1/trackEvent";

export const HAS_CHOSEN_ACCOUNT = "cotb_has_chosen_account";
export interface CorpAccountPickerModalProps {
  open: boolean;
  onClose: () => void;
  headerImage?: JSX.Element;
  title: string;
  accounts: CorpBusinessAccount[];
  sessionInfo: CorpSessionInfo;
  variant?: "modal" | "switcher";
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    maxWidth: "546px",
    maxHeight: "665px",
  },
  drawer: {
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: theme.spacing(2.5),
  },
  accountsContent: {
    padding: theme.spacing(1),
  },
  accountsList: {
    maxHeight: "30vh",
    overflowY: "auto",
    paddingBottom: 0,
    paddingTop: 0,
  },
  ctaContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1, 1, 2.5, 1),
  },
}));

export const CorpAccountPickerModal: React.FC<CorpAccountPickerModalProps> = ({
  open,
  onClose,
  headerImage,
  title,
  accounts,
  sessionInfo,
  variant = "modal",
}) => {
  const classes = useStyles();
  const { matchesMobile } = useDeviceTypes();
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(
    null
  );
  const [currentIsPrimary, setCurrentIsPrimary] = useState(false);
  const [isAccountSwitchLoading, setIsAccountSwitchLoading] = useState(false);
  const [hasScroll, setHasScroll] = useState(false);
  const dialogContentRef = useRef<HTMLElement>(null);

  const CORPORATE_ADMIN_URL = window.__portal_env__?.ADMIN_PORTAL_URL ?? "";

  const nonOptedInSectionLabel =
    "Eligible for Capital One Travel for Business:";

  const onboardingPage = `${CORPORATE_ADMIN_URL}/onboarding`;

  const findCurrentSelectedAccount = (id: string) => {
    return accounts.find(
        (account) =>
            account.rewardsAccount.accountReferenceId === id
    );
  }

  const [optedInAccounts, nonOptedInAccounts] = accounts.reduce(
    (result, account) => {
      if (account.optedIn) {
        result[0].push(account);
      } else {
        result[1].push(account);
      }

      return result;
    },
    [[] as CorpBusinessAccount[], [] as CorpBusinessAccount[]]
  );

  const currentEntryPointLocation =
    window.location.pathname.split("/")[1] || "";

  useEffect(() => {
    if (open) {
      trackEvent({
        eventName: matchesMobile ? CORP_SWITCH_ACCOUNT : MODAL_ALERT,
        properties: {
          type: "select_account_modal",
          entry_point: `traveler_portal_${currentEntryPointLocation}`,
        },
      });
    }
  }, [open]);

  useEffect(() => {
    if (selectedAccountId === null && optedInAccounts.length > 0) {
      const initialId = sessionInfo.corporateInfo.accountReferenceId ??
          optedInAccounts[0].rewardsAccount.accountReferenceId;
      setSelectedAccountId(
        initialId
      );
      const selectedAccount = findCurrentSelectedAccount(initialId);

      setCurrentIsPrimary(selectedAccount?.rewardsAccount.customerAccountRole === CustomerAccountRole.Primary ?? false);
    }
  }, [optedInAccounts]);

  const handleAccountSelect = (id: string) => {
    setSelectedAccountId(id);
    const selectedAccount = findCurrentSelectedAccount(id);

    setCurrentIsPrimary(selectedAccount?.rewardsAccount.customerAccountRole === CustomerAccountRole.Primary ?? false);
  };

  const handleSubmit = () => {
    sessionStorage.setItem(HAS_CHOSEN_ACCOUNT, "true");
    const selectedAccount =
      accounts.find(
        (account) =>
          account.rewardsAccount.accountReferenceId === selectedAccountId
      ) || null;

    trackEvent({
      eventName: matchesMobile ? CORP_SWITCH_ACCOUNT_CONTINUE : MODAL_ALERT_CHOICE,
      properties: {
        type: "select_account_modal",
        entry_point: `traveler_portal_${currentEntryPointLocation}`,
        opted_in: selectedAccount && "optedIn" in selectedAccount,
      },
    });

    if (
      selectedAccount?.optedIn?.businessId ===
      sessionInfo.corporateInfo.businessId
    ) {
      onClose();
      return;
    }

    if (selectedAccount?.optedIn) {
      setIsAccountSwitchLoading(true);
      changeBusinessInUse({
        businessId: selectedAccount.optedIn.businessId,
      })
        .then((response) => {
          setIsAccountSwitchLoading(false);
          window.location.href = response.request.responseURL;
        })
        .catch((e) => {
          setIsAccountSwitchLoading(false);
          console.error("Issue changing business", e);
        });
    } else {
      window.location.replace(onboardingPage);
    }
    onClose();
  };

  useEffect(() => {
    const checkScroll = () => {
      const element = dialogContentRef.current;
      if (element) {
        const hasVerticalScroll = element.scrollHeight > element.clientHeight;
        setHasScroll(hasVerticalScroll);
      }
    };

    checkScroll();
  }, [optedInAccounts, nonOptedInAccounts]);

  const renderModalView = () => (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        className: classes.dialogPaper,
      }}
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "35px",
          ...(matchesMobile && { paddingBottom: 0 }),
        }}
        disableTypography
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: matchesMobile ? "100%" : "330px",
            rowGap: "10px",
          }}
        >
          {headerImage}
          <Typography
            variant="h2"
            align="center"
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            {title}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent
        ref={dialogContentRef}
        style={{
          padding: "0 30px",
          ...(matchesMobile && { padding: "0 15px" }),
        }}
      >
        <List
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          {optedInAccounts.map((account) => (
            <CorpAccountItemModal
              account={account}
              selected={
                selectedAccountId === account.rewardsAccount.accountReferenceId
              }
              onChange={handleAccountSelect}
            />
          ))}
        </List>

        {nonOptedInAccounts.length > 0 && (
          <>
            <Typography
              style={{
                fontStyle: "italic",
                fontSize: "12px",
                fontWeight: "bold",
                color: "#10253F",
                margin: "14px 20px 8px",
              }}
            >
              {nonOptedInSectionLabel}
            </Typography>

            <List
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              {nonOptedInAccounts.map((account) => (
                <CorpAccountItemModal
                  account={account}
                  selected={
                    selectedAccountId ===
                    account.rewardsAccount.accountReferenceId
                  }
                  onChange={handleAccountSelect}
                />
              ))}
            </List>
          </>
        )}
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: "center",
          padding: "20px 0",
          boxShadow: hasScroll ? "0px 0px 4px 0px rgba(0, 0, 0, 0.25)" : "none",
        }}
      >
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          style={{
            width: "160px",
            ...(matchesMobile && {
              width: "100%",
              fontSize: "20px",
              margin: "0 15px",
              padding: "15px 16px",
            }),
          }}
        >
          {isAccountSwitchLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Continue"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderMobileSwitcherView = () => (
    <SwipeableDrawer
      onClose={onClose}
      onOpen={() => {}}
      open={open}
      anchor="bottom"
      swipeAreaWidth={56}
      disableSwipeToOpen={false}
      ModalProps={{ keepMounted: true }}
      classes={{
        paper: classes.drawer,
      }}
    >
      <Box className={classes.header}>
        <Typography
          variant="h6"
          style={{
            fontSize: "20px",
          }}
        >
          {title}
        </Typography>
      </Box>

      <Box className={classes.accountsContent}>
        <List className={classes.accountsList}>
          {optedInAccounts.map((account) => (
            <CorpAccountItemModal
              account={account}
              selected={
                account.rewardsAccount.accountReferenceId === selectedAccountId
              }
              onChange={handleAccountSelect}
              onSwitcher
            />
          ))}
        </List>
        {nonOptedInAccounts.length > 0 && (
          <>
            <Typography
              style={{
                fontStyle: "italic",
                fontSize: "12px",
                fontWeight: "bold",
                color: "#10253F",
                paddingLeft: "10px",
              }}
            >
              {nonOptedInSectionLabel}
            </Typography>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <List className={classes.accountsList}>
                {nonOptedInAccounts.map((account) => (
                  <CorpAccountItemModal
                    account={account}
                    selected={
                      selectedAccountId ===
                      account.rewardsAccount.accountReferenceId
                    }
                    onChange={handleAccountSelect}
                    onSwitcher
                  />
                ))}
              </List>
            </Box>
          </>
        )}
        <Box className={classes.ctaContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{
              width: "90%",
              fontSize: "20px",
            }}
          >
            {isAccountSwitchLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Continue"
            )}
          </Button>
          {currentIsPrimary && (
                  <Typography
                      component="p"
                      style={{
                        color: "#606060",
                        fontSize: "12px",
                        fontStyle: "italic",
                        marginTop: "10px",
                      }}
                  >
                    Rewards balances are visible to Primary cardholders only.
                  </Typography>
              )}
        </Box>
      </Box>
    </SwipeableDrawer>
  );

  return matchesMobile && variant === "switcher"
    ? renderMobileSwitcherView()
    : renderModalView();
};
