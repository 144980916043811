import React, { useLayoutEffect } from "react";
import { useTheme } from "@material-ui/core";
import clsx from "clsx";
import { useDeviceTypes } from "halifax";
import { RouteComponentProps } from "react-router-dom";
import { ExperimentState, TripsModuleProps } from "redmond";
import { isCorpTenant, useUserContext } from "@capone/common";

import { PAWTUCKET_TRIPS_MODULE_ID } from "../../utils/moduleIds";
import useMicrofrontend from "../../hooks/useMicrofrontend";
import config from "../../utils/config";
import { colors as caponeColors } from "../../utils/capone/colors";
import { colors as corpColors } from "../../utils/capone-corporate/colors";
import "./styles.scss";

interface ITripsModuleProps extends RouteComponentProps {
  experiments?: ExperimentState;
  language: string;
}

const TripsModule = (props: ITripsModuleProps) => {
  const { experiments, language, ...routerProps } = props;
  const { history } = routerProps;
  const id = PAWTUCKET_TRIPS_MODULE_ID;
  const theme = useTheme();
  const { matchesDesktop, matchesSmallDesktop, matchesMobile } =
    useDeviceTypes();
  const { isLoaded, ptTripsModule } = useMicrofrontend(
    id,
    (config.ptTripsModuleUrl += "")
  );
  const { sessionInfo, logo } = useUserContext(config.TENANT);

  useLayoutEffect(() => {
    if (!ptTripsModule) return;
    const { render, unMount } = ptTripsModule;
    const tripModuleProps: TripsModuleProps = {
      experiments,
      theme,
      isAgentPortal: config.ptIsAgentPortal,
      language: language,
      colors: isCorpTenant(config.TENANT) ? corpColors : caponeColors,
      baseHistory: history,
      clientAssets: {
        logo: <img src={logo?.src} alt={logo?.alt} />,
        sessionInfo,
      },
    };

    render(id, tripModuleProps);
    return () => {
      unMount(id);
    };
  }, [isLoaded]); // eslint-disable-line

  if (!isLoaded) return null;
  if (!ptTripsModule) return <h2>ERROR! Cannot find Module</h2>;
  return (
    <div
      className={clsx(
        "trips-module-container",
        {
          desktop: matchesDesktop || matchesSmallDesktop,
        },
        {
          mobile: matchesMobile,
        }
      )}
      id={id}
    />
  );
};

export default TripsModule;
